var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "应用" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入应用ID或名称或appkey" },
                    model: {
                      value: _vm.searchInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "name", $$v)
                      },
                      expression: "searchInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入客户ID或者手机号" },
                    model: {
                      value: _vm.searchInfo.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "keyword", $$v)
                      },
                      expression: "searchInfo.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "ID", width: "120" }
          }),
          _c("el-table-column", { attrs: { label: "应用名称", prop: "name" } }),
          _c("el-table-column", { attrs: { label: "版本", prop: "version" } }),
          _c("el-table-column", {
            attrs: { label: "所属客户", prop: "userId" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "【" +
                        _vm._s(scope.row.userId) +
                        "】" +
                        _vm._s(scope.row.phone)
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "商城内容" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.mallContent(scope.row.appSubService)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "付费功能" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.features(scope.row.appSubService)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { label: "appKey", prop: "appKey" } }),
          _c("el-table-column", {
            attrs: { label: "到期时间", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatDate")(scope.row.expireTime)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.settingApp(scope.row)
                          }
                        }
                      },
                      [_vm._v("设置")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeSettingDialog,
            visible: _vm.dialogSettingForm,
            title: "应用设置"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSettingForm = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "settingFormData",
              attrs: {
                model: _vm.settingFormData,
                "label-position": "right",
                "label-width": "80px",
                rules: _vm.settingFormDataRule
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订购服务", prop: "checkList" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.settingFormData.checkList,
                        callback: function($$v) {
                          _vm.$set(_vm.settingFormData, "checkList", $$v)
                        },
                        expression: "settingFormData.checkList"
                      }
                    },
                    _vm._l(_vm.subServiceList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { float: "left", padding: "0 20px" }
                        },
                        [
                          item.discountPrice > 0
                            ? _c("div", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticStyle: {
                                          display: "block",
                                          "text-align": "center"
                                        },
                                        attrs: { label: item.ID }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.title +
                                              "(" +
                                              item.discountPrice / 100 +
                                              item.priceUnit +
                                              ")"
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                item.buyed
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v("有效期："),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(item.expired)
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "续费天数", prop: "days" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.settingFormData.days,
                      callback: function($$v) {
                        _vm.$set(_vm.settingFormData, "days", _vm._n($$v))
                      },
                      expression: "settingFormData.days"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeSettingDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.buttonDisabled },
                  on: { click: _vm.addtimes }
                },
                [_vm._v("续 费")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: "弹窗操作"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.formData,
                "label-position": "right",
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户ID:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "userId", _vm._n($$v))
                      },
                      expression: "formData.userId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应用名称:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应用名称:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.logo,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "logo", $$v)
                      },
                      expression: "formData.logo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "1--公众号 2--小程序 3--个号 4--APP 5--外部公众号 6--外部机器人 7--外部小程序 8--普通H5 99--推老板(老):"
                  }
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-text": "是",
                      "inactive-text": "否",
                      clearable: ""
                    },
                    model: {
                      value: _vm.formData.type,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "type", $$v)
                      },
                      expression: "formData.type"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "appKey字段:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.appKey,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "appKey", $$v)
                      },
                      expression: "formData.appKey"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "appSecret字段:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.appSecret,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "appSecret", $$v)
                      },
                      expression: "formData.appSecret"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "令牌(用户ID加密):" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.token,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "token", $$v)
                      },
                      expression: "formData.token"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "IV(用户ID加密):" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.alphabet,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "alphabet", $$v)
                      },
                      expression: "formData.alphabet"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "域名:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.host,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "host", $$v)
                      },
                      expression: "formData.host"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "status字段:" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-text": "是",
                      "inactive-text": "否",
                      clearable: ""
                    },
                    model: {
                      value: _vm.formData.status,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模式：1-自营；2-代理:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.mode,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "mode", _vm._n($$v))
                      },
                      expression: "formData.mode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "短信剩余条数:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.smsCount,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "smsCount", _vm._n($$v))
                      },
                      expression: "formData.smsCount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "饿了么PID:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.elmPid,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "elmPid", $$v)
                      },
                      expression: "formData.elmPid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "短信签名:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.smsSignature,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "smsSignature", $$v)
                      },
                      expression: "formData.smsSignature"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码登录模式临时字段用来判断app:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.spacialColumn,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "spacialColumn", _vm._n($$v))
                      },
                      expression: "formData.spacialColumn"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "domain字段:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.domain,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "domain", $$v)
                      },
                      expression: "formData.domain"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "subDomain字段:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.subDomain,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "subDomain", $$v)
                      },
                      expression: "formData.subDomain"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }